<template>
    <q-page>
        <router-view/>
    </q-page>
</template>

<script>

import { mapState } from "vuex";

export default {
    name: "Preventivi",
    data() {
        return {
        }
    },
    computed: {
        ...mapState({
            idPreventivoStore: state => state.formPreventivo.preventivo.id,
        })
    },
    watch: {
        async idPreventivoStore(newValue, oldValue) {
            console.log(`cambiato id preve nello store da ${oldValue} a ${newValue}`);
        }
    },
    updated() {
        //console.log("Gestione preventivi updated");
    },
    destroyed() {
        //console.log("Gestione preventivi destroyed");
    },
    activated() {
        //console.log("Gestione preventivi activated");
    },
}
</script>

<style scoped lang="scss">
    .panel-cerca {
        border: 1px SOLID #C0C0C0;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 25px;
        color: #000;
    }
</style>


